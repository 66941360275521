import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Arrow from "../../../assests/icons/arrowTop.svg";
import white_arro from "../../../assests/icons/white_arro.svg";     /*theme*/
import { fetchCategoryFromApi } from "../../layout/category/categoryApi";

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const Breadcrumbs = ({ product }) => {
	const location = useLocation();
	const [cateGoryApi, setCategoryApi] = useState(null);
	const pathnames = location.pathname.split("/").filter((x) => x);
	let breadcrumbPath = "";

	// Fetch categories from API using useEffect
	useEffect(() => {
		const fetchCategoryData = async () => {
			const data = await fetchCategoryFromApi();
			setCategoryApi(data);
		};
		fetchCategoryData();
	}, []);

	// main category //
	 let categories = cateGoryApi?.ParentCategory.find(
		(sub) => sub.parent_category_name === product?.CategoryName
	  );

	// main Subcategory //
	  let subCategories = categories?.SubCategory.find(
		(sub) => sub.category_name === product?.SubCategoryName
	  );

	const getLinkPath = (name, index) => {
		// Custom handling for certain paths
		if (breadcrumbPath.includes("product")) {
			return `/product/${product.Name}`;
		} else if (breadcrumbPath.includes("category")) {
			return `/category/${pathnames[index]}`;
		} else if (breadcrumbPath.includes("blog")) {
			return "/blogs/all-blogs";
		} else {
			// General path structure for other cases
			return breadcrumbPath;
		}
	};
	return (
		<div className="breadcrumbs">
			<Link to="/">Home</Link>
			{pathnames.map((name, index) => {
				// Accumulate breadcrumb path
				breadcrumbPath += `/${name}`;
				const isLast = index === pathnames.length - 1;
                //console.log(isLast,product.Name)
				return isLast ? (
					<span key={breadcrumbPath}>
					  <img src={white_arro} alt="Arrow" className="breadcrumb-arrow" />
					  {product?.Name  ? (
						<>
						  <Link to={`/category/${product?.CategoryName}`}>{product?.CategoryName}</Link>
						  <img src={white_arro} alt="Arrow" className="breadcrumb-arrow" />
						  <Link to={`/category/${product?.CategoryName}/${product?.SubCategoryName}?id=${subCategories?.id}`}>
							{product?.SubCategoryName}
						  </Link>
						</>
					  ) : (
						capitalizeFirstLetter(name)
					  )}
					</span>
				  ) : (
					breadcrumbPath.includes("product") && name.includes(product?.Name) && (
					  <span key={breadcrumbPath}>
						{/* Uncomment and customize the following lines as needed */}
						<img src={white_arro} alt="Arrow" className="breadcrumb-arrow" /> 
						 <Link to={getLinkPath(name, index)} className="product">{capitalizeFirstLetter(name)}</Link>
					  </span>
					)
				  );
				  
			})}
			{/* Render arrow and product name if on product page */}
			{breadcrumbPath.includes("product") && product && (
				<span key="product-name">
					<img src={white_arro} alt="Arrow" className="breadcrumb-arrow" />{" "}
					<span className="breadcrumb-product-name">{product.Name}</span>
				</span>
			)}
		</div>
	);
};

export default Breadcrumbs;
